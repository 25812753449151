<template>
    <li class="d-flex justify-content-between align-items-center mb-3">
        <span class="d-flex align-items-center">
            <i :class="`fa ${connectedAppConfig.icon} fa-2x text-${connectedAppConfig.id} align-middle`"></i>
            <span class="align-middle mx-2 text-capitalize" v-text="connectedApp ? appData.name : app"></span>
            <plan-badge v-if="connectedAppConfig.plan === 'pro'" plan="pro"></plan-badge>
        </span>
        <b-spinner
            v-if="isLoading"
            small
            variant="success"
            :label='$t("connection.state")'>
        </b-spinner>
        <div v-else-if="'plan' in connectedAppConfig && profile.plan != connectedAppConfig['plan']">
            <a v-if="!connectedApp" @click="showUpgradeModal()" href="javascript:void(0)">
                <span class="reset-link">
                    <small>{{ $t("connection.itemLabelNotConnected") }}</small>
                </span>
            </a>
            <small v-else class="text-green">{{ $t("connection.itemLabelConnected") }}</small>
            <b-modal ref="upgrade-modal" title="Upgrade your membership" centered>
                <p class="text-center mb-3">
                    Upgrade your membership to PRO to connect to this app!
                </p>
                <template #modal-footer>
                    <b-button variant="primary" :href="routes.upgrade(profile.slug, 'accounts.publish_reels', routes.connect(profile.slug, app))">
                        upgrade
                    </b-button>
                </template>
            </b-modal>
        </div>
        <div v-else>
            <div v-if="app !== 'facebook'">
                <!-- TO BE REFACTORED ONCE WE HAVE ALL OTHER APPLICATIONS CONNECTION FLOWS -->
                <a :href="routes.connect(profile.slug, app)">
                    <span
                        v-if="(applicationState.installed == true) && (applicationState.connected == false)"
                        class="error-link"
                        @click="showDisconnectModal($event, app)"
                    >
                        <small>{{ $t("connection.itemLabelDisconnected") }}</small>
                        <app-disconnected-modal
                            :app="app"
                            :profile="profile"
                        ></app-disconnected-modal>
                    </span>
                    <span v-else-if="!connectedApp" class="reset-link">
                        <small>{{ $t("connection.itemLabelNotConnected") }}</small>
                    </span>
                </a>
                <small v-if="connectedApp" class="text-green">{{ $t("connection.itemLabelConnected") }}</small>
            </div>
            <div v-else>
                <facebook-connect-link :current="appData ? appData.acc_id : undefined" :connected="connectedApp">
                </facebook-connect-link>
            </div>
        </div>

    </li>
</template>

<script>
    import axios from '@/setup/axios-setup.js';
    import AppDisconnectedModal from '@/components/AppDisconnectedModal.vue';
    import FacebookConnectLink from '@/components/connections/FacebookConnectLink.vue';
    import { routes } from '@/components/common/views-config.js';
    import { appStringProp } from '@/utils/props.js';
    import { connectionConfigValues } from './apps-connection-config';

    export default {
        name: 'AppsConnectionItem',
        props: {
            profile: {
                type: Object,
                required: true
            },
            app: appStringProp,
            endpoint: {
                type: String
            }
        },
        components: {
            AppDisconnectedModal,
            FacebookConnectLink,
        },
        data: function () {
            return {
                appData: {},
                connectedApp: false,
                connectedAppConfig: connectionConfigValues[this.$props.app],
                isLoading: false,
                routes: routes
            };
        },
        methods: {
            get_app_profile_data() {
                this.appData = this.$props.profile.applications[this.$props.app].data;
            },
            get_oauth() {
                if(this.$props.endpoint && this.$props.profile.applications[this.$props.app].active && this.appData.acc_id){
                    this.isLoading = true;
                    axios.get(`/api/${this.$props.endpoint}/${this.appData.acc_id}`)
                        .then((response) => {
                            this.isLoading = false;
                            const data = response.data;

                            if (data.result === 'ok') {
                                this.connectedApp = true;
                                this.connectionUpdate(this.$props.app, true, true);
                            } else {
                                this.connectionUpdate(this.$props.app, true, false);
                            }
                        })
                        .catch(() => {
                            this.isLoading = false;
                            this.connectedApp = false;
                            this.connectionUpdate(this.$props.app, true, false);
                        });
                }
            },
            validate() {
                if (this.$props.app in this.$props.profile.applications) {
                    this.get_app_profile_data();
                    this.get_oauth();
                }
            },
            connectionUpdate(app, install, connection) {
                const appName = app;
                const s = window.store;
                const currentState = s ? s.getters.getApplicationState(appName) : {};
                const newState = { ...currentState, installed: install, connected: connection };
                if (s) {
                    s.dispatch('applicationState', { appName, newState });
                }
            },
            showUpgradeModal() {
                this.$refs['upgrade-modal'].show()
            },
            showDisconnectModal(event, app) {
                event.preventDefault();
                this.$bvModal.show(`${app}-disconnected-modal`);
            }
        },
        mounted() {
            this.validate();
        },
        computed: {
            applicationState() {
                return window.store ? window.store.getters.getApplicationState(this.$props.app) : {};
            }
        }
    }
</script>
