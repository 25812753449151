<template>
    <b-modal
        :busy="loading"
        :cancel-title="$t('cancelLabel')"
        :hide-header-close="loading"
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        :title="title"
        @cancel="$emit('cancel');"
        @ok="confirmAction"
        cancel-variant="light"
        ref="this-modal"
    >
        <b-overlay :show="loading" rounded="sm">
            <div>
                <!-- content slot -->
                <slot></slot>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
    import axios from '@/setup/axios-setup.js';
    import Toast from '@/toast.js';
    const toast = new Toast();

    export default {
        name: 'confirm-and-wait-modal',
        emits: ['cancel'],
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            params: {
                type: Object,
                default: ()=>({}),
            },
            method: {
                type: String,
                default: 'get',
                validator: (value) => ['get', 'post', 'put', 'patch', 'delete'].includes(value.toLowerCase()),
            },
            title: {
                type: String,
                default: 'Confirm action',
            },
            okCallback: {
                type: Function,
                default: null,
            },
            errorCallback: {
                type: Function,
                default: null,
            },
            successMessage: {
                type: String,
                default: 'Action successfully completed',
            },
        },
        data() {
            return {
                loading: false,
            };
        },
        methods: {
            confirmAction(event) {
                event.preventDefault();
                this.loading = true;
                const config = {
                    method: this.method.toLowerCase(),
                    url: this.endpoint,
                    data: this.params,
                }
                axios(config)
                    .then((response) => {
                        this.$refs['this-modal'].hide()
                        toast.success(this.successMessage, "Success");
                        this.loading = false;

                        if (this.okCallback) {
                            this.okCallback(response)
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response.data.error, "Error")
                        this.loading = false;

                        if (this.errorCallback) {
                            this.errorCallback(error)
                        }
                    });
            },
        },
        i18n: {
            messages: {
                en: {
                    cancelLabel: "Cancel"
                },
                es: {
                    cancelLabel: "Cancelar"
                },
                fr: {
                    cancelLabel: "Annuler"
                }
            }
        }
    };
</script>
