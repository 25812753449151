<template>
    <div class="row">
        <div class="col-12">
            <h5 class="title mb-4 d-flex justify-content-between justify-content-lg-start">
                Calendar
            </h5>

            <app-multi-select v-model:value="selectedApps"></app-multi-select>
            <bu-calendar :selectedApps="selectedApps"></bu-calendar>

        </div>
    </div>
</template>

<script>
    import AppMultiSelect from '@/components/calendar/AppMultiSelect.vue';
    import BUCalendar from '@/components/calendar/BUCalendar.vue';

    export default {
        name: 'CalendarDashboard',
        components: {
            AppMultiSelect,
            'bu-calendar': BUCalendar,
        },
        data() {
            return {
                selectedApps: [],
            }
        },
    }
</script>
