import { formatDate } from '@/utils/datetime.js';

const getScheduledEmailCampaignListAPI = (emarketingId, fireAtFrom=null, fireAtTo=null, queryType=null) => {
    const fromParam = fireAtFrom ? `&fire_at_from=${formatDate.longISO(fireAtFrom)}` : '';
    const toParam = fireAtTo ? `&fire_at_to=${formatDate.longISO(fireAtTo)}` : '';
    const queryTypeParam = queryType ? `&query_type=${queryType}` : ''
    const allParams = [fromParam, toParam, queryTypeParam].filter(p => p).join('')
    return `/api/scheduled_email_campaign/?emarketing=${emarketingId}${allParams}`;
}

const getScheduledPostingListAPI = (accId, provider=null, status=null, deleted=null, fireAtFrom=null, fireAtTo=null, createdFrom=null, createdTo=null, top=null, ordering=null, page=null) => {
    const providerParam = provider !== null ? `&provider=${provider}` : '';
    const statusParam = status !== null ? `&status=${status}` : '';
    const fromParam = fireAtFrom ? `&fire_at_from=${formatDate.longISO(fireAtFrom)}` : '';
    const toParam = fireAtTo ? `&fire_at_to=${formatDate.longISO(fireAtTo)}` : '';
    const deletedParam = deleted !== null ? `&deleted=${deleted}` : '';
    const orderingParam = ordering && !top ? `&ordering=${ordering}` : '';
    const createdFromParam = createdFrom ? `&created_from=${formatDate.longISO(createdFrom)}` : '';
    const createdToParam = createdTo ? `&created_to=${formatDate.longISO(createdTo)}` : '';
    const topParam = top ? `&top=${top}` : '';
    const pageParam = page ? `&page=${page}` : '';
    const allParams = [providerParam, statusParam, deletedParam, fromParam, toParam, createdFromParam, createdToParam, topParam, orderingParam, pageParam].filter(p => p).join('')
    return `/api/scheduled_postings/?pgid=${accId}${allParams}`;
}

export const endpoints = {
    'facebook-login-url': profileId => `/api/facebook/${profileId}/login_url/`,
    'facebook-list-pages': profileId => `/api/facebook/${profileId}/list_pages/`,
    'facebook-select-page': (profileId, pageId) => `/api/facebook/${profileId}/select_page/?page_id=${pageId}`,
    'scheduledemailcampaign-list': getScheduledEmailCampaignListAPI,
    'scheduledposting-list': getScheduledPostingListAPI,
};
