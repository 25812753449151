<template>
    <confirm-and-wait-modal
        id="pageSelectorModal"
        :endpoint="selectPageEndpoint"
        method="post"
        :params="{ page_id: selectedPageId }"
        :ok-disabled="!selectedPageId"
        @cancel="resetState"
        :successMessage="$t('facebook.selected')"
        :title="$t('facebook.selectOne')"
        :okCallback="reload"
    >
        <endpoint-fetcher
            :endpoint="listPagesEndpoint"
            v-model:loading="loading"
            v-model:fetchedData="pages"
            v-model:thereIsNoData="noPagesAvailable"
        >
            <template v-if="pages && pages.length">
                <b-form-radio-group
                    id="pages-radio-form"
                    name="radio-btn-outline"
                    class="mb-3"
                    size="lg"
                    v-model="selectedPageId"
                    plain
                    stacked
                >
                    <b-form-radio
                        v-for="page in pages"
                        :disabled="page.taken && !isCurrent(page.id)"
                        :key="page.id"
                        :value="page.id"
                        class="mb-1 d-flex align-items-center"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                :src="page.picture_url"
                                alt="Page Thumbnail"
                                class="mr-2"
                                style="width: 40px; height: 40px; border-radius: 50%;"
                            />
                            <span class="ml-1">
                                {{ page.name }} {{ page.taken ? isCurrent(page.id) ? '(current)' : '(taken)' : '' }}
                            </span>
                        </div>
                    </b-form-radio>
                </b-form-radio-group>
            </template>
            <accordion-item>
                <template #toggle>
                    {{ $t('facebook.faqTitle') }}
                </template>
                <template #content>
                    {{ $t('facebook.faqPleaseVerify') }}
                    <ul>
                        <li>{{ $t('facebook.verify1') }}</li>
                        <li>{{ $t('facebook.verify2') }}</li>
                    </ul>
                </template>
            </accordion-item>
        </endpoint-fetcher>
    </confirm-and-wait-modal>
</template>

<script>
import EndpointFetcher from '../common/EndpointFetcher.vue';
import ConfirmAndWaitModal from '../common/ConfirmAndWaitModal.vue';
import AccordionItem from '../common/AccordionItem.vue';
import { endpoints } from '../common/api-config.js';

export default {
    name: 'facebook-page-selector-modal',
    components: {
        AccordionItem,
        ConfirmAndWaitModal,
        EndpointFetcher
    },
    props: {
        current: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            noPagesAvailable: false,
            pages: [],
            selectedPageId: this.$props.current || null,
            loading: false,
        };
    },
    methods: {
        isCurrent(pageId) {
            return this.current === pageId;
        },
        checkOpenConnections() {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('open_connections') === 'facebook') {
                this.$bvModal.show('pageSelectorModal');
            }
        },
        closeModal() {
            this.resetState();
            this.$bvModal.hide('pageSelectorModal');
        },
        reload() {
            window.location.href = window.location.origin + window.location.pathname;
        },
        resetState() {
            this.pages = [];
            this.selectedPageId = null;
            this.noPagesAvailable = false;
        },
    },
    computed: {
        profileId() {
            return window.profileData ? window.profileData.pk : '';
        },
        listPagesEndpoint() {
            return endpoints['facebook-list-pages'](this.profileId);
        },
        selectPageEndpoint() {
            return endpoints['facebook-select-page'](this.profileId, this.selectedPageId);
        }
    },
    mounted() {
        this.checkOpenConnections();
    },
};
</script>
