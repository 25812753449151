import Vue, { createApp, defineComponent, ref } from 'vue';
import axios from './setup/axios-setup';
import * as Sentry from '@sentry/vue';
import moment from 'moment-timezone';
import flatPickr from 'vue-flatpickr-component';
import VueLazyload from 'vue3-lazyload';
import vSelect from 'vue-select';
import Toast from './toast.js';
import './jquery-global.js';
import 'bootstrap';
import 'popper.js/dist/umd/popper.min.js';
import { BootstrapVue } from 'bootstrap-vue';
import bsCustomFileInput from 'bs-custom-file-input';
import "inputmask";
import * as BupLegacy from './legacy.js';
import { ContentLoader } from 'vue-content-loader';
import * as FilePond from 'filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import select2 from 'select2';
import Shepherd from 'shepherd.js';
import { i18n, loadProfileLang } from './setup/i18n-setup.js';
import store from './store.js';
import { connectionConfigValues } from './components/apps-connection-config.js';
import Quill from 'quill/core';
import Toolbar from "quill/modules/toolbar";
import Snow from "quill/themes/snow";
import Bold from "quill/formats/bold";
import Italic from "quill/formats/italic";
import Header from "quill/formats/header";
import Link from 'quill/formats/link';

Link.sanitize = function(url) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
}

Quill.register({
  "modules/toolbar": Toolbar,
  "themes/snow": Snow,
  "formats/bold": Bold,
  "formats/italic": Italic,
  "formats/header": Header,
  "formats/link": Link,
});

// Custom components
import AccordionItem from './components/common/AccordionItem.vue';
import AdvisorDashboard from './pages/AdvisorDashboard.vue';
import AppsConnection from './components/AppsConnection.vue';
import CalendarDashboard from './pages/CalendarDashboard.vue';
import ConfirmAndWaitModal from './components/common/ConfirmAndWaitModal.vue'
import ContactListImporterExporter from './components/emarketing/contacts/ContactListImporterExporter.vue'
import ContactsTable from './components/emarketing/contacts/ContactsTable.vue'
import EmailCampaignLinks from './components/emarketing/campaigns/EmailCampaignLinks.vue';
import EmailCampaignPerformance from './components/emarketing/campaigns/EmailCampaignPerformance.vue';
import EmailCampaignPreview from './components/emarketing/campaigns/EmailCampaignPreview.vue';
import EmailCampaignSummary from './components/emarketing/campaigns/EmailCampaignSummary.vue';
import EmarketingPostList from './components/emarketing/EmarketingPostList.vue';
import EndpointFetcher from './components/common/EndpointFetcher.vue';
import FacebookPageSelectorModal from './components/connections/FacebookPageSelectorModal.vue';
import GeneratorMain from './components/generator/GeneratorMain.vue';
import SocialPosts from './pages/SocialPosts.vue';
import ItemList from './components/common/ItemList.vue';
import LandingPagesSearch from './components/landing_pages/LandingPagesSearch.vue';
import LandingPagesTree from './components/landing_pages/LandingPagesTree.vue';
import SupplierBudget from './components/social/reports/SupplierBudget.vue'
import PlanBadge from './components/common/PlanBadge.vue';
import PostItemsPeriods from './components/post/PostItemsPeriods.vue';
import PremiumCard from './components/PremiumCard.vue';
import ProfileWidget from './components/ProfileWidget.vue';
import RichTextEditor from './components/common/RichTextEditor.vue';
import UpgradeToPremiumLink from './components/plans/UpgradeToPremiumLink.vue';

import '@/assets/scss/main.scss';

Vue.config.productionTip = false;

const initSentry = app => {
  const isDEV = window.debug;
  const isQA = true;  // for future implementation
  const isPROD = !isDEV && !isQA;
  const env = isDEV ? 'DEV' : isQA ? 'QA' : 'PROD';

  if (!isDEV) {
    Sentry.init({
      app,
      environment: env,
      release: `${env}@${process.env.VUE_APP_VERSION}`,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      debug: !isPROD,
      tracesSampleRate: isPROD ? 0.2 : 1,
      tracingOptions: {
        trackComponents: true,
      },
      logErrors: isPROD ? false : true,
      attachProps: true,
      attachStacktrace: true,
    });
  }
}


const initApp = (app, selector='#appl') => {
  if (!app) {
    alert('No app declared to initialize');
  } else {
    if (window.lang && window.lang !== i18n.locale) {
      loadProfileLang(window.lang);
    }
    // app.config.warnHandler = (warning, vm, instance, trace) => {
    //   // Until BootstrapVue has a Vue3 compatible version, hide Vue warnings about it.
    //   const isBootstrapVueRegex = /at <B[A-Z]\w*>.*/;
    //   if (!instance || !isBootstrapVueRegex.test(instance)) {
    //     console.warn(warning, { instance, vm, trace });
    //   }
    // };
    initSentry(app);

    // mixed components (django template + Vue)
    app.component('bc-modal', defineComponent(window.modalComponentDef))
    app.component('profile-list', defineComponent(window.profileListComponentDef))
    app.component('plan-badge', defineComponent(window.PlanBadge))
    // -->

    app.use(BootstrapVue);
    app.use(i18n);
    app.mount(selector);
  }
}

window.initApp = initApp;
window.i18n = i18n;
window.Vue = Vue;
window.createApp = createApp;
window.defineComponent = defineComponent;
window.ref = ref;

// libs
window.axios = axios;
window.store = store;
window.moment = moment;
window.VueFlatpickr = flatPickr;
window.VueLazyload = VueLazyload;
window.vSelect = vSelect;
window.toast = new Toast();
window.process_api_form_response = BupLegacy.process_api_form_response;
window.render_form_errors = BupLegacy.render_form_errors;
window.ContentLoader = ContentLoader;
window.FilePond = FilePond;
window.FilePondPluginFileEncode = FilePondPluginFileEncode;
window.FilePondPluginImagePreview = FilePondPluginImagePreview;
window.FilePondPluginImageResize = FilePondPluginImageResize;
window.FilePondPluginImageValidateSize = FilePondPluginImageValidateSize;
window.FilePondPluginImageTransform = FilePondPluginImageTransform;
window.select2 = select2;
window.Shepherd = Shepherd;
window.bsCustomFileInput = bsCustomFileInput;
window.Quill = Quill;

// Custom components setup
window.AccordionItem = AccordionItem;
window.AppsConnection = AppsConnection;
window.ConfirmAndWaitModal = ConfirmAndWaitModal;
window.ConnectionConfigValues = connectionConfigValues;
window.ContactListImporterExporter = ContactListImporterExporter;
window.ContactsTable = ContactsTable;
window.EmailCampaignLinks = EmailCampaignLinks;
window.EmailCampaignPerformance = EmailCampaignPerformance;
window.EmailCampaignPreview = EmailCampaignPreview;
window.EmailCampaignSummary = EmailCampaignSummary;
window.EmarketingPostList = EmarketingPostList;
window.EndpointFetcher = EndpointFetcher;
window.FacebookPageSelectorModal = FacebookPageSelectorModal;
window.ItemList = ItemList;
window.LandingPagesSearch = LandingPagesSearch;
window.LandingPagesTree = LandingPagesTree;
window.SupplierBudget = SupplierBudget;
window.PlanBadge = PlanBadge;
window.PostItemsPeriods = PostItemsPeriods;
window.PremiumCard = PremiumCard;
window.ProfileWidget = ProfileWidget;
window.RichTextEditor = RichTextEditor;
window.UpgradeToPremiumLink = UpgradeToPremiumLink;

// pages
window.AdvisorDashboard = AdvisorDashboard;
window.CalendarDashboard = CalendarDashboard;
window.GeneratorMain = GeneratorMain;
window.SocialPosts = SocialPosts;
