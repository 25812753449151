<template>
    <div class="input-group">
        <flat-pickr ref="datepicker" :config="config" placeholder="Select a Date" v-model="selectedValue" @on-open="showConfirm" @on-close="hideConfirm"></flat-pickr>
        <div class="input-group-append">
            <button v-if="confirm" class="btn btn-success" type="button" @click="confirmDate">
                <i class="fa fa-fw fa-check">
                    <span aria-hidden="true" class="sr-only">{{ $t("common.toggle") }}</span>
                </i>
            </button>
        </div>
    </div>
</template>

<script>
    import flatPickr from 'vue-flatpickr-component';

    const minDateDeltaMinutes = 10;

    export default {
        name: 'DatePicker',
        emits: ['update:value'],
        props: ['value'],
        components: {
            flatPickr,
        },
        data(){
            return {
                selectedValue: this.$props.value,
                confirm: false,
                config: {
                    altFormat: "Y-m-d at h:i K",
                    altInput: true,
                    enableTime: true,
                    time_24hr: false,
                    dateFormat: "Z",
                    locale: window.lang,
                    minDate: this.getMinDate(),
                    wrap: true,
                },
            }
        },
        methods: {
            showConfirm(){
                this.confirm = true;
            },
            hideConfirm(){
                this.confirm = false;
            },
            confirmDate(){
                this.$refs.datepicker.fp.close();
            },
            getMinDate() {
                let date = new Date();
                // Add minDateDeltaMinutes minutes to the current date and time so the post doesn't desapear
                date.setMinutes(date.getMinutes() + minDateDeltaMinutes);
                return date;
            },
        },
        watch: {
            selectedValue(newValue) {
                this.$emit('update:value', newValue);
            }
        }
    };
</script>
