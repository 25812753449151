<template>
    <div class="mb-4">
        <article class="card card--shadow-border mb-4">
            <section class="card-body">
                <section class="d-flex justify-content-between mb-2">
                    <div>
                        <div class="d-flex">
                            <div v-if="post.logo" class="align-self-center mr-2">
                                <img :src="post.logo" height="48" alt="">
                            </div>
                            <div>
                                <h3 class="h5 mb-0 mt-1">{{ pageName }}</h3>
                                <p class="small mb-0">
                                    <span v-if="post.deleted" class="text-danger mr-1">
                                        <i class="fa fa-exclamation" aria-hidden="true"></i>
                                        <span v-if="post.status == 0">{{ $t("common.deleted") }}</span>
                                        <span v-else>{{ $t("common.deletedByFrequency") }}</span>
                                    </span>
                                    <span v-if="post.status == 10" class="text-success mr-1">
                                        <i class="fa fa-check"  aria-hidden="true"></i>
                                        {{ $t("common.posted") }}
                                    </span>
                                    <span v-if="post.status == 0">
                                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                                        {{ $t("common.scheduled") }}
                                    </span>
                                    {{ post.fire_at_formatted }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <post-actions :post="post" @add-item="addItem" @update-item="updateItem" refresh-parent="updateUpcomingCol"></post-actions>
                </section>

                <p class="text-breakspaces mb-0">{{ post.message_with_hashtags }}</p>
            </section>
            <main class="card-footer card-footer--no-padding card-footer--no-link">
                <video
                    v-if="post.video"
                    :src="post.video"
                    :poster="post.image"
                    controls
                    controlsList="nodownload"
                    class="embed-responsive embed-responsive-21by9 post-video">
                </video>

                <div
                    v-if="!post.video && post.image"
                    class="overflow-hidden rounded-bottom">
                    <a
                        v-if="post.external_link || post.link"
                        :href="post.external_link || post.link"
                        target="_blank"
                        :title="post.link_title">
                        <img
                            v-lazy="{ src: post.image }"
                            alt=""
                            class="img-fluid">
                        <div
                            :class="'post_clicks' in post.stats || 'post_impressions_unique' in post.stats || 'post_engaged_users' in post.stats ? '' : 'rounded-bottom'"
                            class="border">
                            <p class="px-3 pt-2 pb-1 mb-0 text-uppercase">
                                <small>{{ post.domain }}</small>
                            </p>
                            <p v-if="post.title" class="mb-0 px-3 pb-3 h5">
                                {{ post.title }}
                            </p>
                        </div>
                    </a>
                    <img
                        v-if="!post.link && !post.external_link"
                        v-lazy="{ src: post.image }"
                        alt=""
                        class="img-fluid">
                </div>

                <div
                    v-if="'post_clicks' in post.stats || 'post_impressions_unique' in post.stats || 'post_engaged_users' in post.stats"
                    class="bg-white rounded-bottom border-right border-left border-bottom p-3">
                    <div class="d-flex justify-content-between px-3">
                        <div>
                            <h4 class="h6 mb-0">{{ post.stats.post_impressions_unique }}</h4>
                            <p class="mb-0 small">{{ $t("common.reach") }}</p>
                        </div>
                        <div>
                            <h4 class="h6 mb-0">{{ post.stats.post_engaged_users }}</h4>
                            <p class="mb-0 small">{{ $t("common.engagement") }}</p>
                        </div>
                        <div>
                            <h4 class="h6 mb-0">{{ post.stats.post_clicks }}</h4>
                            <p class="mb-0 small">{{ $t("common.clicks") }}</p>
                        </div>
                    </div>
                </div>
            </main>
        </article>
    </div>
</template>

<script>
    import PostActions from './PostActions.vue';
    import { appStringProp } from '@/utils/props.js';
    import { formatDate } from '@/utils/datetime.js';

    export default {
        components: { PostActions },
        name: 'PostSocial',
        emits: ['add-item', 'update-item'],
        props: {
            app: appStringProp,
            item: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                post: this.parseItem(this.$props.item)
            };
        },
        methods: {
            parseItem(item){
                if (item && item.fire_at) {
                    item.fire_at = formatDate.parseWithTimeZone(item.fire_at);
                    item.fire_at_formatted = item.fire_at.format("LLLL");
                }
                return item;
            },
            updateItem(post){
                this.post = this.parseItem(post);
                this.$emit('update-item', this.post);
            },
            addItem(post){
                this.$emit('add-item', post);
            }
        },
        computed: {
            pageName(){
                return window.profileData.applications[this.app].data.name;
            },
        }
    }
</script>
