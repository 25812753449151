<template>
    <div>
        <span v-if="!connected" href="javascript:void(0)">
            <endpoint-fetcher :endpoint="loginEndpoint" v-model:fetchedData="connectURL" style="min-height: 0;">
                <a class="reset-link" :href="connectURL">
                    <small>{{ $t("connection.itemLabelNotConnected") }}</small>
                </a>
            </endpoint-fetcher>
        </span>
        <small v-else class="text-green">{{ $t("connection.itemLabelConnected") }}</small>
        <facebook-page-selector-modal :current="current"></facebook-page-selector-modal>
    </div>
</template>

<script>
    import FacebookPageSelectorModal from './FacebookPageSelectorModal.vue';
    import EndpointFetcher from '@/components/common/EndpointFetcher.vue';
    import { endpoints } from '../common/api-config.js';

    export default {
        name: 'facebook-connect-link',
        props: {
            current: {
                type: String,
                default: ''
            },
            connected: {
                type: Boolean,
                required: true
            }
        },
        components: {
            EndpointFetcher,
            FacebookPageSelectorModal,
        },
        data(){
            return {
                connectURL: '',
            }
        },
        computed: {
            profileId() {
                return window.profileData ? window.profileData.pk : '';
            },
            loginEndpoint() {
                return endpoints['facebook-login-url'](this.profileId);
            }
        },
    }
</script>
